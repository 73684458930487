import React, {useState, useEffect} from 'react';
import {Helmet} from "react-helmet";

// Style
import './demo.scss';

import logo from '../images/logoDemo.png';
import and from '../images/and.png';
import data from '../data.js';

import deckVideo from '../videos/deck.mp4'

// markup
const Demo = ({location}) => {
  const [name, setName] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get("id");
    const index = data.findIndex(e => e.id === id);
    if(data && data[index]) {
      setName(data[index].name);
    }
  }, [location, data]);

  return (
    <main className={"demoContainer"}>
      <Helmet title={"Demo"}>
        <html lang="fr" />
        <meta charSet="utf-8" />        
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-63J6THBWF0"></script>
        <script dangerouslySetInnerHTML={{__html:`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-63J6THBWF0');`}}>
          
        </script>
      </Helmet>
      <div className="titleContainer">
        <img className={'logo'} src={logo} alt="logo" />
        <h1>Demo Video</h1>
      </div>
      <div className="video">
        <video controls onPlay={"gtag('event', 'click', {'event_category': 'button', 'event_label': 'vidéo lancée'});"} onEnded={"gtag('event', 'click', {'event_category': 'button', 'event_label': 'vidéo finie'});"}>
          <source src={deckVideo}
                  type="video/mp4" />
        </video>
      </div>
    </main>
  )
}

export default Demo

